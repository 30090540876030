<template>
  <b-card>
    <!-- media -->
    <b-media no-body>
      <b-media-aside>
        <b-link>
          <b-img
            ref="previewEl"
            rounded
            :src="avatar"
            height="80"
          />
        </b-link>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-100 ml-30">
        <h3>{{name}}</h3>
         <b-card-text>{{optionsLocal.email}}</b-card-text>
      </b-media-body>
    </b-media>
    <!--/ media -->
    <!-- form -->
    <b-form class="mt-2">
      <b-row>
        <b-col sm="6">
          <b-form-group
            label="User Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.username"
              name="name"
              placeholder="Name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="E-mail"
            label-for="account-e-mail"
          >
            <b-form-input
              v-model="optionsLocal.email"
              name="email"
              placeholder="Email"
              
            />

          </b-form-group>
        </b-col>
        <b-col sm="6">
          <b-form-group
            label="Facebook ID"
            label-for="account-company"
          >
            <b-form-input
              v-model="optionsLocal.facebook"
              name="company"
              placeholder="Facebook"
              
            />
          </b-form-group>
        </b-col>
         <b-col sm="6">
          <b-form-group
            label="Name"
            label-for="account-name"
          >
            <b-form-input
              v-model="optionsLocal.name"
              name="name"
              placeholder="Name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-2 mr-1"
            @click.prevent="updateInfo"
          >
            Save changes
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            type="reset"
            class="mt-2"
            @click.prevent="resetForm"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RepositoryFactory from '../../../api/RepositoryFactory'
const UsersRepository = RepositoryFactory.get('user')

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      optionsLocal: Object.assign({},this.$store.getters['auth/userInfo']),
      generalData: this.$store.getters['auth/userInfo'],
      profileFile: null,
      avatar: "https://ui-avatars.com/api/?name=Teleper.io&size=40&background=0D8ABC&color=fff&rounded=true",
      name: Object.assign({},this.$store.getters['auth/userInfo']).name
    }
  },
  methods: {
    resetForm() {
      this.optionsLocal = Object.assign({},this.$store.getters['auth/userInfo'])
    },
    updateInfo(){
      UsersRepository.updateInfo(this.optionsLocal).then(rs=>{
        this.$store.commit('auth/update_info',{user:this.optionsLocal})
        this.optionsLocal = Object.assign({},this.$store.getters['auth/userInfo'])
        this.name = this.optionsLocal.name
        this.$bvToast.toast('Saved!', {
          title: 'Success',
          variant: 'success',
          solid: false,
        })
      }).catch(e=>{
        this.$bvToast.toast('Error '+e.message, {
          title: 'Error',
          variant: 'danger',
          solid: false,
        })
      })
    }
  },
  created(){
    if(!this.$store.getters['auth/userInfo']){
      setTimeout(()=>{
        this.optionsLocal = this.$store.getters['auth/userInfo']
        this.generalData = this.$store.getters['auth/userInfo']
      },2000)
      
    }
    this.avatar = `https://ui-avatars.com/api/?name=${this.optionsLocal.name}&size=40&background=0D8ABC&color=fff&rounded=true`
  },
}
</script>
